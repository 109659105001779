export default {
  greeting:
    "Hallo, ich bin Julia und helfe dir gerne bei Fragen rund um die Ausbildung & Bewerbung bei Kramp.",

  suggestions: [],
  noAnswerText:
    "Leider kann ich Ihre Frage nicht beantworten. Bitte versuchen Sie es mit einer anderen Formulierung",
  noAnswerSuggestions: [],
  botName: "Kramp",
  botTitle: "",
  client: "https://analytics-api.institut.digital",

  endpoint: "/knowledgebases/23fb9a3f-7d3c-4c0c-bfa6-9f8d66a0936b/generateAnswer",
  endpointAuthKey: "955cd5f4-211c-4eee-832e-061983caf941",

  // confidence threshold in percent
  confidenceScore: 0,

  primaryColor: "#AE1E00",
  secondaryColor: "",

  // e.g https://analytics-api.institut.digital/api/ext/XYZ
  analyticsEndpoint: "zrscAAHOWi9ova3DJsIEakTvOoW6EVtX",
  useAnalytics: false,
  logGreeting: false,
};
