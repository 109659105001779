import axios from "axios";
import config from "../bot-config/bot-config";

export default ({ commit, getters }, { type, message }) => {
  // send data to analytics tool
  if (config.analyticsEndpoint && config.useAnalytics) {
    axios
      .post("https://analytics-api.institut.digital/api/ext/0rOJp6EUfRi51Ce3aLKuBEwW9SL3HLae", {
        item: {
          lang: "de",
          body: message.text,
          sender: type,
          chat_instance_id: getters.chatInstanceId,
        },
      })
      .then((r) => {
        if (getters.chatInstanceId === null) {
          commit("setChatInstanceId", r.data.chat_instance_id);
        }
      })
      .catch(() => {
        // console.log(e);
      });
  }
};
